import React from 'react'
import { Link } from 'gatsby'

import { INTERNAL_URLS, EXTERNAL_URLS } from '../../config/urls'

import landingStyles from '../Landing/Landing.module.scss'
import styles from './Providers.module.scss'

import DrNishImg from '../images/dr-nish.jpg'
import DrHannImg from '../images/cecelia.jpg'
import DrIzuImg from '../images/dr-izu.jpg'
import PatImg from '../images/patricia.jpeg'
import LindaImg from '../images/linda.jpeg'
import HenryMayoImg from '../images/henry-mayo.jpeg'
import HolyCrossImg from '../images/holy-cross.jpeg'

export class Providers extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showingNish: false,
      showingHann: false,
      showingIzu: false,
      showingLinda: false
    }
  }

  toggleShow = name => {
    return () => {
      const newState = {}
      newState[name] = !this.state[name]
      this.setState(newState)
    }
  }

  render() {
    return (
      <div>
        <div className={styles.page}>
          <div className={styles.pageContent}>
            <div className={styles.pageHeader}>
              <div className={landingStyles.aboutSectionBorder} />
              <h2 className={styles.headerTitle}>Our Providers</h2>
              <div className={styles.headerSubtitle}>
                <p>Our mission statement is to provide the best care at the right time for the right patients because we care about your experience.</p>
                <p>We offer general obstetrics, high risk obstetrics as well as gynecology and specialty care(infertility, hormonal therapy such as bioidenticals and hormone pellet treatments for menopause), hereditary cancer evaluation. We believe in partnering with our patients to make a true difference in their health.</p>
              </div>
            </div>
            <div className={styles.providers}>
              <div className={styles.provider}>
                <div
                  className={styles.providerImg}
                  style={{ backgroundImage: `url(${DrNishImg})` }}
                  role="img"
                  aria-label="Dr. Don J. Nishiguchi, M.D."
                />
                <h3 className={styles.providerName}>
                  Dr. Don J. Nishiguchi, M.D.
                </h3>
                <div className={styles.providerDesc}>
                  <p>
                    Dr. "Nish" is a board certified obstetrician/gynecologist and has been in private practice for over 20 years in Santa Clarita. He is a graduate of University of Southern California, Ohio State University Medical Center and U.S.C in Obstetrics/Gynecology program.
                  </p>
                  <p>
                    His knowledge, caring demeanor and empathy are well known among his patients. His patients tell us that Dr "Nish" is a type of doctor who will "hold their hands."
                  </p>
                  <p>
                    Dr. Nishiguchi’s interests are general and high risk obstetrics, contraception, infertility diagnosis and treatments, Poly Cystic Ovarian Syndrome, pap smear problems as well as Premenstrual Tension Syndrome.
                  </p>
                  {this.state.showingNish && (
                    <>
                      <p>
                        He also focuses on the diagnosis and treatment of irregular menses, osteoporosis and menopausal issues and offering benign and cancerous gynecologic surgeries including minimally invasive procedures in hysterectomy, myomectomy and laparoscopy.
                      </p>
                      <p>
                        For women who are suffering with very heavy menses, Dr. Nishgiuchi offers minimally invasive endometrial ablation to minimize further bleeding.
                      </p>
                    </>
                  )}
                  <div className="mt-1">
                    <a
                      onClick={this.toggleShow('showingNish')}
                      className={styles.providerToggle}
                    >
                      {this.state.showingNish ? 'Hide Details' : 'More Details'}
                    </a>
                  </div>
                </div>
              </div>
              <div className={styles.provider}>
                <div
                  className={styles.providerImg}
                  style={{ backgroundImage: `url(${DrHannImg})` }}
                  role="img"
                  aria-label="Dr. Cecelia M. Hann, M.D."
                />
                <h3 className={styles.providerName}>
                  Dr. Cecelia M. Hann, M.D.
                </h3>
                <div className={styles.providerDesc}>
                  <p>
                    Dr. Hann is a board certified obstetrician/gynecologist and has been in private practice for over 20 years in Santa Clarita. She is a graduate of University of Southern California, Ohio State University Medical Center and U.C.L.A/Harbor Medical Center in Obstetrics/Gynecology program.
                  </p>
                  <p>
                    She focuses on gynecologic practice with strong interest in pap smear management, contraception, infertility evaluation, Poly Cystic Ovarian Syndrome, irregular and heavy menstrual disorders, menopause, osteoporosis and Premenstrual Tension Syndrome. Bioidentical hormone therapies are also of interest to Dr. Hann.
                  </p>
                  {this.state.showingHann && (
                    <p>
                      Dr. Hann also offered both benign and cancerous gynecologic surgeries including minimally invasive gynecologic hysterectomy, myomectomy, laparoscopy. Endometrial Ablation for heavy bleeders with resulting anemia is also of interest to Dr Hann.
                    </p>
                  )}
                </div>
                <div className="mt-1">
                  <a
                    onClick={this.toggleShow('showingHann')}
                    className={styles.providerToggle}
                  >
                    {this.state.showingHann ? 'Hide Details' : 'More Details'}
                  </a>
                </div>
              </div>
              <div className={styles.provider}>
                <div
                  className={styles.providerImg}
                  style={{ backgroundImage: `url(${DrIzuImg})` }}
                  role="img"
                  aria-label="Dr. Jerry K. Izu, M.D."
                />
                <h3 className={styles.providerName}>Dr. Jerry K. Izu, M.D.</h3>
                <div className={styles.providerDesc}>
                  <p>
                    Dr. Izu received his medical degree from The Uniformed
                    Services University of the Health Sciences in 2000. After
                    graduation, he continued as an Assistant Professor in
                    Obstetrics and Gynecology. In 2004, he completed his
                    residency training in Obstetrics and Gynecology at Tripler
                    Army Medical Center in Honolulu, Hawaii.
                  </p>
                  <p>
                    After residency, Dr. Izu continued his military career at
                    Fort Hood, Texas. There he was Chief of Gynecology at Carl
                    R. Darnall Medical Center, supervising 10 other physicians
                    and providing medical care to two divisions of soldiers,
                    their families and retired beneficiaries.
                    {!this.state.showingIzu && '..'}
                  </p>
                  {this.state.showingIzu && (
                    <>
                      <p>
                        Dr. Izu was on faculty for the San Antonio Uniformed
                        Services Health Education Consortium, training OB/GYN
                        residents to perform minimally invasive surgery,
                        laparoscopic hysterectomy, operative vaginal deliveries,
                        Cesarean deliveries, infertility treatment and other
                        OB/GYN procedures.
                      </p>
                      <p>
                        His military service included a tour with the 47th
                        Combat Support Hospital in Tikrit, Iraq where he was
                        Medical Director. He was awarded the Meritorious Service
                        Medal for providing outstanding medical care in an
                        austere environment, treating patients with rare
                        complications such as brain aneurysms and malaria.
                        Providing medical care to military members and their
                        families afforded Dr. Izu the unique experience of
                        helping these patients through their health problems
                        while dealing with the added stressors of deployment and
                        separation.
                      </p>
                      <p>
                        In 2011, Dr. Izu was honorably discharged from the
                        United States Army and relocated with his wife
                        Bernadette and their six children to Santa Clarita so
                        that they could be close to their extended family. Dr.
                        Izu enjoys working together with his patients to achieve
                        their health goals and is always accessible to each
                        patient by personal cell phone.
                      </p>
                    </>
                  )}
                  <div className="mt-1">
                    <a
                      onClick={this.toggleShow('showingIzu')}
                      className={styles.providerToggle}
                    >
                      {this.state.showingIzu ? 'Hide Details' : 'More Details'}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.providers}>
              <div className={styles.provider}>
                <div
                  className={styles.providerImg}
                  style={{ backgroundImage: `url(${PatImg})` }}
                  role="img"
                  aria-label="Patricia Alamos-Donnelly, BSN, CNM"
                />
                <h3 className={styles.providerName}>
                  Patricia Alamos-Donnelly, BSN, CNM
                </h3>
                <div className={styles.providerDesc}>
                  <p>
                    Patricia is a member of American College of Certified Nurse Midwives as well as a Nurse Practitioner who has been in private practice for over 20 years.
                  </p>
                  <p>
                    She is a graduate of University of Southern California Midwifery program. Patricia specializes in general prenatal and postnatal obstetrics as well as gynecology. Pap smear issues, contraception(IUDs and subdermals), menstrual disorders, abnormal bleeding and hormonal imbalance conditions are some of her interests.
                  </p>
                  <p>
                    Patricia is an avid world traveler and scrapbooker, and is a loving mother of three daughters.
                  </p>
                </div>
              </div>
              <div className={styles.provider}>
                <div
                  className={styles.providerImg}
                  style={{ backgroundImage: `url(${LindaImg})` }}
                  role="img"
                  aria-label="Linda M. Faydo, MSN, CNM"
                />
                <h3 className={styles.providerName}>
                  Linda M. Faydo, MSN, CNM
                </h3>
                <div className={styles.providerDesc}>
                  <p>
                    Linda is a member of American College
                    of Certified Nurse Midwives as well as a Nurse Practitioner
                    who has been in private practice for 15 years. She is a
                    graduate of University of California Los Angeles Nurse
                    Midwifery program.
                  </p>
                  <p>
                    Linda also specializes in general prenatal and postnatal
                    obstetrical care, routine gynecologic services such as pap
                    smear and contraceptive management, menstrual disorders,
                    sexually transmitted diseases, Poly Cystic Ovarian Syndrome,
                    Premenstrual Tension Syndrome as well as general gynecologic
                    health.
                  </p>
                  {this.state.showingLinda && (
                    <>
                      <p>
                        Linda has strong interest in bio-identical and
                        traditional hormones for menopause as well as genetic
                        mutation testing such as BRCA and CLORIS for breast, ovarian
                        and colon cancer risks.
                      </p>
                      <p>
                        Linda believes in "partnering" with her patients to achieve
                        optimal health. She has two daughters and her hobbies
                        include scrap booking and traveling.
                      </p>
                    </>
                  )}
                </div>
                <div className="mt-1">
                  <a
                    onClick={this.toggleShow('showingLinda')}
                    className={styles.providerToggle}
                  >
                    {this.state.showingLinda ? 'Hide Details' : 'More Details'}
                  </a>
                </div>
              </div>
              <div className={styles.provider}></div>
            </div>
            <div className={styles.team}>
              <h3 className={styles.teamTitle}>
                Our Dedicated Staff&nbsp;&nbsp;❤️
              </h3>
              <div className={styles.providerStaff}>
                <div className={styles.teamPerson}>Margie</div>
                <div className={styles.teamPerson}>Yolanda</div>
                <div className={styles.teamPerson}>Michelle</div>
                <div className={styles.teamPerson}>Martha</div>
                <div className={styles.teamPerson}>Yvonne</div>
                <div className={styles.teamPerson}>Natalie</div>
                <div className={styles.teamPerson}>Yesenia</div>
                <div className={styles.teamPerson}>Emma</div>
                <div className={styles.teamPerson}>Kassi</div>
              </div>
            </div>
            <div className={styles.hospitalSection}>
              <div className={landingStyles.aboutSectionBorder} />
              <h2 className={styles.midpageHeader}>
                Hospital Affiliations
              </h2>
              <div className={styles.hospitals}>
                <div className={styles.hospital}>
                  <img src={HenryMayoImg} className={styles.hospitalImg} />
                  <h3 className={styles.hospitalName}>
                    Henry Mayo Newhall Hospital
                  </h3>
                  <div className={styles.hospitalDesc}>
                    McBean Pkwy, Valencia CA
                  </div>
                </div>
                <div className={styles.hospital}>
                  <img src={HolyCrossImg} className={styles.hospitalImg} />
                  <h3 className={styles.hospitalName}>
                    Holy Cross Hospital
                  </h3>
                  <div className={styles.hospitalDesc}>
                    Mission Hills
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
