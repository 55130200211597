import React from 'react'

import Layout from '../components/layout'
import { Providers } from '../components/Providers'

export const ProvidersPage = () => (
  <Layout seo={{ title: 'Providers' }}>
    <Providers />
  </Layout>
)

export default ProvidersPage
